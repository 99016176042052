/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import { Box, Flex } from "rebass"
import {
  MdCreditCard,
  MdChevronRight,
  MdAddCircleOutline,
  MdBlock,
  MdSwapHoriz,
  MdMailOutline,
  MdEdit,
  MdContentPaste,
} from "react-icons/md"

import {
  Container,
  Layout,
  Section,
} from "@giraldomac/gatsby-theme-mmdbase/src/components/layout"
import { SEO } from "@giraldomac/gatsby-theme-mmdbase/src/components/elements"
import { PubHero, CallToAction } from "../../../components/elements"

const Index = ({ data }) => {
  const page = data.prismic.account

  return (
    <Layout>
      <SEO
        meta_title={page.meta_title}
        meta_description={page.meta_description}
      />
      <PubHero title={RichText.asText(page.title)} />
      <Section>
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">
            {/* Pay My Bill */}
            <Box width={["full", 4 / 5, 3 / 4]} color="white">
              <Box
                sx={{
                  bg: "blue.5",
                  p: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 sx={{ margin: 0 }}>Pay Bill</h2>
                <MdCreditCard sx={{ fill: "blue.6", fontSize: "3em" }} />
              </Box>
              <Box
                sx={{
                  bg: "blue.6",
                  p: 4,
                }}
              >
                <p sx={{ marginBottom: 0 }}>
                  At PUB, we value our customers' time, and understand that they
                  have varying schedules and preferences. That's why we offer
                  five convenient ways to ensure our customers can pay their
                  utility bills on time.
                </p>
                <Link
                  to="/customer-service/account/pay-bill"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    display: "block",
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    "&:hover": { color: "white", textDecoration: "underline" },
                  }}
                >
                  Learn More <MdChevronRight />
                </Link>
              </Box>
            </Box>

            {/* Start Service */}
            <Box width={["full", 4 / 5, 3 / 4]} color="white">
              <Box
                sx={{
                  bg: "green.5",
                  p: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 sx={{ margin: 0 }}>Start Service</h2>
                <MdAddCircleOutline sx={{ fill: "green.6", fontSize: "3em" }} />
              </Box>
              <Box
                sx={{
                  bg: "green.6",
                  p: 4,
                }}
              >
                <p sx={{ marginBottom: 0 }}>
                  Have you moved or are you planning to move to the Parkersburg
                  area? Apply for your water and sewer services to be set up and
                  ready for use in no time by filling out our online form.
                </p>
                <Link
                  to="/customer-service/account/start-service"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    display: "block",
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    "&:hover": { color: "white", textDecoration: "underline" },
                  }}
                >
                  Learn More <MdChevronRight />
                </Link>
              </Box>
            </Box>

            {/* End Service */}
            <Box width={["full", 4 / 5, 3 / 4]} color="white">
              <Box
                sx={{
                  bg: "yellow.5",
                  p: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 sx={{ margin: 0 }}>End Service</h2>
                <MdBlock sx={{ fill: "yellow.6", fontSize: "3em" }} />
              </Box>
              <Box
                sx={{
                  bg: "yellow.6",
                  p: 4,
                }}
              >
                <p sx={{ marginBottom: 0 }}>
                  No matter the reason for ending your utility services, PUB has
                  provided an accurate way for you to do so. Fill out the
                  information included in our online form if you need to
                  terminate your water or sewer services with PUB.
                </p>
                <Link
                  to="/customer-service/account/end-service"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    display: "block",
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    "&:hover": { color: "white", textDecoration: "underline" },
                  }}
                >
                  Learn More <MdChevronRight />
                </Link>
              </Box>
            </Box>

            {/* Transfer Location */}
            <Box width={["full", 4 / 5, 3 / 4]} color="white">
              <Box
                sx={{
                  bg: "orange.5",
                  p: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 sx={{ margin: 0 }}>Transfer Location</h2>
                <MdSwapHoriz sx={{ fill: "orange.6", fontSize: "3em" }} />
              </Box>
              <Box
                sx={{
                  bg: "orange.6",
                  p: 4,
                }}
              >
                <p sx={{ marginBottom: 0 }}>
                  Moving is an exciting time, but can be a lot of work. Take the
                  stress out of starting over, and have running water and
                  working sewer facilities at your new location ready when you
                  get there.
                </p>
                <Link
                  to="/customer-service/account/transfer-location"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    display: "block",
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    "&:hover": { color: "white", textDecoration: "underline" },
                  }}
                >
                  Learn More <MdChevronRight />
                </Link>
              </Box>
            </Box>

            {/* Change Mailing Address */}
            <Box width={["full", 4 / 5, 3 / 4]} color="white">
              <Box
                sx={{
                  bg: "red.5",
                  p: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 sx={{ margin: 0 }}>Change Mailing Address</h2>
                <MdMailOutline sx={{ fill: "red.6", fontSize: "3em" }} />
              </Box>
              <Box
                sx={{
                  bg: "red.6",
                  p: 4,
                }}
              >
                <p sx={{ marginBottom: 0 }}>
                  If you would like for PUB to mail your monthly utility bills
                  to a different address, we are happy to do so. By filling out
                  our online application, you can ensure a successful change
                  will be made to your mailing address.
                </p>
                <Link
                  to="/customer-service/account/change-address"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    display: "block",
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    "&:hover": { color: "white", textDecoration: "underline" },
                  }}
                >
                  Learn More <MdChevronRight />
                </Link>
              </Box>
            </Box>

            {/* Name Change */}
            <Box width={["full", 4 / 5, 3 / 4]} color="white">
              <Box
                sx={{
                  bg: "purple.5",
                  p: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 sx={{ margin: 0 }}>Name Change</h2>
                <MdEdit sx={{ fill: "purple.6", fontSize: "3em" }} />
              </Box>
              <Box
                sx={{
                  bg: "purple.6",
                  p: 4,
                }}
              >
                <p sx={{ marginBottom: 0 }}>
                  Keeping your account information up-to-date is crucial to
                  maintaining or growing your credit, as well as ensuring
                  consistent and working utility services from PUB. If there has
                  been a change to the legal name associated to your account,
                  please update us by using our online form.
                </p>
                <Link
                  to="/customer-service/account/name-change"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    display: "block",
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    "&:hover": { color: "white", textDecoration: "underline" },
                  }}
                >
                  Learn More <MdChevronRight />
                </Link>
              </Box>
            </Box>

            {/* Customer Forms */}
            <Box width={["full", 4 / 5, 3 / 4]} color="white">
              <Box
                sx={{
                  bg: "indigo.5",
                  p: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 sx={{ margin: 0 }}>Customer Forms</h2>
                <MdContentPaste sx={{ fill: "indigo.6", fontSize: "3em" }} />
              </Box>
              <Box
                sx={{
                  bg: "indigo.6",
                  p: 4,
                }}
              >
                <p sx={{ marginBottom: 0 }}>
                  We strive to provide the resources our customers need
                  regarding their current water and sewer services, information
                  about adjustments to services, and more. Download or customer
                  forms for more information.
                </p>
                <Link
                  to="/customer-service/account/customer-forms"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    display: "block",
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    "&:hover": { color: "white", textDecoration: "underline" },
                  }}
                >
                  Learn More <MdChevronRight />
                </Link>
              </Box>
            </Box>
          </Flex>
        </Container>
      </Section>

      <CallToAction />
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      account(lang: "en-us", uid: "account") {
        content
        meta_description
        meta_title
        title
      }
    }
  }
`

export default Index
